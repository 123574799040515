import { FunctionComponent } from 'react'
import OurTeamSection from '../components/OurTeamSection'
import OurLogo from '../components/OurLogo'
import AreasExpertiseTitle from '../components/AreasExpertiseTitle'
import AdvisoryServices from '../components/AdvisoryServices'
import WorldOfCryptic from '../components/WorldOfCryptic'
import styles from './index.module.scss'

const CLSitedesktoplandingPage: FunctionComponent = () => {
  return (
    <div className={styles.clSitedesktoplandingPage}>
      <header className={styles.frameA}>
        <a href="/" style={{ flexShrink: 0 }}>
          <img className={styles.frameBIcon} loading="eager" alt="" src="/vector.svg" />
        </a>
        <a className={styles.contactUsButton} href="/contact">
          <div className={styles.contactUs}>Contact us</div>
          <img
            className={styles.arrowOutwardFill0Wght400GrIcon}
            alt=""
            src="/arrow-outward-fill0-wght400-grad0-opsz20-1.svg"
          />
        </a>
      </header>
      <OurTeamSection />
      <OurLogo />
      <AreasExpertiseTitle />
      <AdvisoryServices />
      <WorldOfCryptic />
    </div>
  )
}

export default CLSitedesktoplandingPage
