import { FunctionComponent } from 'react'
import styles from './WorldOfCryptic.module.css'

const WorldOfCryptic: FunctionComponent = () => {
  return (
    <footer className={styles.worldOfCryptic}>
      <div className={styles.contactUsButton}>
        <div className={styles.enigmaText}>
          <a href="/" style={{ flexShrink: 0 }}>
            <img className={styles.stepIntoCryptic} alt="" src="/vector.svg" />
          </a>
          <div className={styles.stepIntoThe}>
            Step into the enigmatic world of Cryptic Labs, boundaries are reshaped in Technology, Security, Privacy, and
            Trust.
          </div>
        </div>
        <a className={styles.contactUsText} href="/contact">
          <div className={styles.contactUs}>Contact us</div>
          <img
            className={styles.arrowOutwardFill0Wght400GrIcon}
            alt=""
            src="/arrow-outward-fill0-wght400-grad0-opsz20-1-2.svg"
          />
        </a>
      </div>
      <div className={styles.backgroundRectangle} />
      <div className={styles.copyright2024Cryptic}>Copyright 2024 Cryptic Labs LLC All Rights Reserved.</div>
    </footer>
  )
}

export default WorldOfCryptic
