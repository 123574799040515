import { FunctionComponent } from 'react'
import Expertises from './Expertises'
import styles from './AreasExpertiseTitle.module.css'

const AreasExpertiseTitle: FunctionComponent = () => {
  return (
    <section className={styles.areasExpertiseTitle}>
      <h1 className={styles.ourAreasOf}>Our Areas of Expertise</h1>
      <div className={styles.expertises}>
        <Expertises
          speakerNotesFILL0Wght300G="/speaker-notes-fill0-wght300-grad0-opsz40-1.svg"
          strategicAdvisoryServices="Strategic Advisory Services"
          weProvideStrategicAdvisor="We offer strategic advisory services to established companies and startups, bridging the gap between venture capital, academia, and industry. Our expertise in security, privacy, and machine learning technologies guides partners through the complexities of the digital landscape."
        />
        <Expertises
          speakerNotesFILL0Wght300G="/award-star-fill0-wght300-grad0-opsz40-1.svg"
          strategicAdvisoryServices="Advanced Technology Expertise"
          weProvideStrategicAdvisor="At Cryptic Labs, our experts in machine learning, security, big data, and AI computing pioneer innovative solutions across industries. Partner with us to unlock the power of next-gen solutions and stay ahead in today's evolving digital landscape."
        />
        <Expertises
          speakerNotesFILL0Wght300G="/currency-bitcoin-fill0-wght300-grad0-opsz40-1.svg"
          strategicAdvisoryServices="Technology Innovation and Acceleration"
          weProvideStrategicAdvisor="As a commercial accelerator, Cryptic Labs empowers startups to innovate and lead in emerging technologies. Join us to transform your ideas into market-leading solutions and accelerate your journey to market leadership."
        />
        <Expertises
          speakerNotesFILL0Wght300G="/shield-fill0-wght300-grad0-opsz40-1.svg"
          strategicAdvisoryServices="Security Research and Innovation"
          weProvideStrategicAdvisor="Cryptic Labs specializes in assisting clients facing fundamental security challenges. We address issues affecting networks, applications, digital transformation efforts, and advanced research and development initiatives."
        />
      </div>
    </section>
  )
}

export default AreasExpertiseTitle
