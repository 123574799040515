import { FunctionComponent } from "react";
import styles from "./Expertises.module.css";

export type ExpertisesType = {
  speakerNotesFILL0Wght300G?: string;
  strategicAdvisoryServices?: string;
  weProvideStrategicAdvisor?: string;
};

const Expertises: FunctionComponent<ExpertisesType> = ({
  speakerNotesFILL0Wght300G,
  strategicAdvisoryServices,
  weProvideStrategicAdvisor,
}) => {
  return (
    <div className={styles.expertises}>
      <img
        className={styles.speakerNotesFill0Wght300GrIcon}
        loading="eager"
        alt=""
        src={speakerNotesFILL0Wght300G}
      />
      <div className={styles.expertises1}>
        <h3 className={styles.strategicAdvisoryServices}>
          {strategicAdvisoryServices}
        </h3>
        <div className={styles.weProvideStrategic}>
          {weProvideStrategicAdvisor}
        </div>
      </div>
    </div>
  );
};

export default Expertises;
