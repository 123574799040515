import { FunctionComponent } from 'react'
import styles from './OurTeamSection.module.css'

const OurTeamSection: FunctionComponent = () => {
  return (
    <section className={styles.ourTeamSection}>
      <div className={styles.welcomeText}>
        <h1 className={styles.crypticLabsShaping}>Cryptic Labs: Shaping Tomorrow’s Solutions.</h1>
        <div className={styles.welcomeToCryptic}>
          Welcome to Cryptic Labs, where unmatched knowledge meets cutting-edge technology, strong security, privacy,
          and trust. Immerse yourself in a realm where advanced solutions and strategic guidance come together, shaping
          the future landscape.
        </div>
      </div>
      <div className={styles.commitmentSection}>
        <a className={styles.advisorContainer} href="/advisors">
          <div className={styles.exploreCrypticAdvisors}>Explore Cryptic advisors</div>
          <img
            className={styles.arrowOutwardFill0Wght400GrIcon}
            alt=""
            src="/arrow-outward-fill0-wght400-grad0-opsz20-1-1.svg"
          />
        </a>
        <a className={styles.advisorContainer1} href="/contact">
          <div className={styles.contactUs}>Contact us</div>
          <img
            className={styles.arrowOutwardFill0Wght400GrIcon1}
            alt=""
            src="/arrow-outward-fill0-wght400-grad0-opsz20-1-2.svg"
          />
        </a>
      </div>
    </section>
  )
}

export default OurTeamSection
