import { FunctionComponent } from "react";
import styles from "./ProfessorNames.module.css";

export type ProfessorNamesType = {
  image59?: string;
  drEricSMaskin?: string;
  ericMaskinIsTheAdamsUnive?: string;
};

const ProfessorNames: FunctionComponent<ProfessorNamesType> = ({
  image59,
  drEricSMaskin,
  ericMaskinIsTheAdamsUnive,
}) => {
  return (
    <div className={styles.professorNames}>
      <img
        className={styles.image59Icon}
        loading="eager"
        alt=""
        src={image59}
      />
      <div className={styles.professorNames1}>
        <h2 className={styles.drEricS}>{drEricSMaskin}</h2>
        <div className={styles.ericMaskinIs}>{ericMaskinIsTheAdamsUnive}</div>
      </div>
    </div>
  );
};

export default ProfessorNames;
