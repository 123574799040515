import { FunctionComponent } from 'react'
import ProfessorNames from './ProfessorNames'
import styles from './AdvisoryServices.module.css'

const AdvisoryServices: FunctionComponent = () => {
  return (
    <section className={styles.advisoryServices}>
      <div className={styles.advisors}>
        <h1 className={styles.meetTheAdvisors}>Meet the Advisors Behind Cryptic Labs</h1>
        <a className={styles.advisors1} href="/advisors">
          <div className={styles.learnMore}>Learn more</div>
          <img
            className={styles.arrowOutwardFill0Wght400GrIcon}
            alt=""
            src="/arrow-outward-fill0-wght400-grad0-opsz20-1-2.svg"
          />
        </a>
      </div>
      <div className={styles.professorImages}>
        <ProfessorNames
          image59="/image-59@2x.png"
          drEricSMaskin="Dr. Eric S. Maskin"
          ericMaskinIsTheAdamsUnive="Eric Maskin is the Adams University Professor and Professor of Economics and Mathematics at Harvard. "
        />
        <ProfessorNames
          image59="/image-60@2x.png"
          drEricSMaskin="Sir Christopher Pissarides"
          ericMaskinIsTheAdamsUnive="Sir Christopher Pissarides is the Regius Professor of Economics at the London School of Economics."
        />
        <ProfessorNames
          image59="/image-56@2x.png"
          drEricSMaskin="Prof. Ueli Maurer"
          ericMaskinIsTheAdamsUnive="Ueli Maurer is professor of computer science and head of the Information Security and Cryptography Research Group."
        />
        <ProfessorNames
          image59="/image-57@2x.png"
          drEricSMaskin="Dr. Jonathan Katz"
          ericMaskinIsTheAdamsUnive="Dr. Jonathan Katz is a professor of computer science at the University of Maryland."
        />
        <ProfessorNames
          image59="/image-58@2x.png"
          drEricSMaskin="Prof. Victor Shoup"
          ericMaskinIsTheAdamsUnive="Victor Shoup is an internationally recognized expert in the field of cryptography."
        />
        <ProfessorNames
          image59="/image-54@2x.png"
          drEricSMaskin="Hilarie Orman"
          ericMaskinIsTheAdamsUnive="Hilaire Orman’s expertise centers on the design, development, analysis of software and systems."
        />
      </div>
    </section>
  )
}

export default AdvisoryServices
