import { FunctionComponent } from 'react'
import styles from './OurLogo.module.css'

const OurLogo: FunctionComponent = () => {
  return (
    <section className={styles.ourLogo}>
      <div className={styles.ourTeamText}>
        <img
          className={styles.scottGraham5fnmwej4taaUnsplIcon}
          loading="eager"
          alt=""
          src="/scottgraham5fnmwej4taaunsplash-1@2x.png"
        />
        <div className={styles.frameC}>
          <h1 className={styles.ourCommitment}>Our Commitment</h1>
          <div className={styles.atCrypticLabs}>
            At Cryptic Labs, we are not your typical agency. We are pioneers in transformative technology, dedicated to
            overcoming industry challenges. With a focus on security, confidentiality, and reliability, we offer
            comprehensive services to enhance the effectiveness of cutting-edge solutions.
          </div>
        </div>
      </div>
      <div className={styles.ourTeamText1}>
        <div className={styles.ourExpertTeamParent}>
          <h1 className={styles.ourExpertTeam}>Our Expert Team</h1>
          <div className={styles.atCrypticLabs1}>
            At Cryptic Labs, our diverse team of experienced security advisors, data scientists, researchers, engineers,
            and skilled professionals in emerging technologies work together globally to provide creative solutions that
            fuel innovation.
          </div>
        </div>
        <img className={styles.ourTeamTextChild} loading="eager" alt="" src="/rectangle-14@2x.png" />
      </div>
    </section>
  )
}

export default OurLogo
